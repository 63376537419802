import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Navbar from 'react-bootstrap/Navbar';

import useBodyRef from 'gatsby-theme-dettol/src/hooks/useBodyRef';
import { isBrowser } from 'gatsby-theme-dettol/src/utils/browser';
import AlertCustom from 'gatsby-theme-dettol/src/components/common/AlertCustom';
import Logo from 'gatsby-theme-dettol/src/components/common/Logo';
import HeaderNav from 'gatsby-theme-dettol/src/components/Header/HeaderNav';
import HeaderSearch from 'gatsby-theme-dettol/src/components/Header/HeaderSearch';

import { IHeader } from 'gatsby-theme-dettol/src/components/Header/models';

import { BODY_CLASS_NAME_MOBILE_MENU } from 'gatsby-theme-dettol/src/components/Header/constants';

import './Header.scss';

const Header: FC<IHeader> = ({
  languageSelector,
  headerTransparent,
  alert,
  brandSettings,
  menu,
  currentPage,
  customFields,
}) => {
  const bodyTag = useBodyRef();
  const stickyRef = useRef<HTMLDivElement | null>(null);

  const [isMenuShown, setMenuShown] = useState(false);
  const [isSearchActive, setSearchShown] = useState(false);
  const [isMenuToggled, setMenuToggled] = useState(false);
  const [isTransparent, setIsTransparent] = useState(true);

  const headerClasses = classNames('dt-header', {
    'dt-header--transparent': headerTransparent && isTransparent,
  });

  const dropMaskClasses = classNames('dt-header__drop-mask', {
    'dt-header__drop-mask--active': isMenuShown || isSearchActive,
  });

  const handleDropMask = useCallback(() => {
    if (bodyTag) {
      setSearchShown(false);
      setMenuShown(false);
      bodyTag.classList.remove(BODY_CLASS_NAME_MOBILE_MENU);
    }
  }, [bodyTag]);

  const handleToggle = useCallback(() => {
    if (bodyTag) {
      isMenuToggled
        ? bodyTag.classList.remove(BODY_CLASS_NAME_MOBILE_MENU)
        : bodyTag.classList.add(BODY_CLASS_NAME_MOBILE_MENU);
      setMenuToggled(!isMenuToggled);
      setIsTransparent(!isTransparent);
    }
  }, [isMenuToggled, bodyTag]);

  const handleToggleOutside = useCallback(() => {
    if (bodyTag) {
      setMenuToggled(false);
      bodyTag.classList.remove(BODY_CLASS_NAME_MOBILE_MENU);
    }
  }, [bodyTag]);

  const handleSticky = () => {
    if (
      isBrowser() &&
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
      const observer = new IntersectionObserver((entries) => {
        setIsTransparent(entries[0].isIntersecting);
      });

      stickyRef && stickyRef.current && observer.observe(stickyRef.current);
    }
  };

  useEffect(() => {
    handleSticky();
  }, []);

  return (
    <>
      {/* DON'T REMOVE: .dt-header__sticky empty div, which helps to handle the scroll offset */}
      <div className="dt-header__sticky" ref={stickyRef} />
      <header className="dt-header__wrapper">
        {alert ? (
          <AlertCustom isFixed closeMenu={handleToggleOutside} {...alert} name="COVID_ALERT" />
        ) : null}

        <div className={headerClasses}>
          <Navbar
            onToggle={handleToggle}
            expand="lg"
            expanded={isMenuToggled}
            aria-label={menu?.navigationAriaLabel}
          >
            <div className="dt-header__nav-holder">
              <Logo brandSettings={brandSettings} />

              <Navbar.Toggle aria-controls="basic-navbar-nav" label={menu?.toggleBtnAriaLabel} />
            </div>

            <Navbar.Collapse id="basic-navbar-nav">
              <HeaderNav
                languageSelector={languageSelector}
                menu={menu}
                showMenu={setMenuShown}
                isMenuShown={isMenuShown}
                currentPage={currentPage}
                handleRedirect={handleDropMask}
              />
            </Navbar.Collapse>
          </Navbar>

          <HeaderSearch
            languageSelector={languageSelector}
            toggle={setSearchShown}
            setMenuToggled={setMenuToggled}
            setMenuShown={setMenuShown}
            isActive={isSearchActive}
            customFields={customFields}
          />
        </div>

        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className={dropMaskClasses} onClick={handleDropMask} />
      </header>
    </>
  );
};

export default Header;
